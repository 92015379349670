import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";

import fade from "./directive/fade";
import slide from "./directive/slide";
import circleMotion from "./directive/circleMotion";

axios.defaults.baseURL = "https://admin.humanvalue.co.kr/api";

const app = createApp(App);

app.directive("fade", fade);
app.directive("slide", slide);
app.directive("circleMotion", circleMotion);
app.config.globalProperties.axios = axios;
app.use(router).mount("#app");
