<template>
  <ToTop />
  <Header />
  <router-view />
  <Footer />
</template>

<script>
import ToTop from '@/components/ToTop'
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { postData } from "./utils/postData";

export default {
  components: {
    ToTop,
    Header,
    Footer,
  },
  mixins: [postData],
  mounted() {
    fetch("//jsonip.com")
      .then((data) => data.json())
      .then((json) => {
        const { ip } = json;
        this.postData("/saveVisitor", { ip }, () => {});
      });
  },
};
</script>

<style>
@import url("./assets/css/reset.css");
@import url("./assets/css/common.css");
@import url("./assets/css/layout.css");
</style>
