import store from "@/store";
import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/partner",
    name: "partner",
    component: () => import(/* webpackChunkName: "partner" */ "../views/Partner.vue"),
  },
  {
    path: "/program/short",
    name: "program_short",
    component: () => import(/* webpackChunkName: "program_short" */ "../views/Program/Program_Short.vue"),
  },
  {
    path: "/program/long",
    name: "program_long",
    component: () => import(/* webpackChunkName: "program_long" */ "../views/Program/Program_Long.vue"),
  },
  {
    path: "/program/recommend",
    name: "program_recommend",
    component: () => import(/* webpackChunkName: "program_recommand" */ "../views/Program/Program_Recommend.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  const header_btn = document.querySelector(".header_container .btn_menu");
  const header_menu = document.querySelector(".menu_wrap");
  header_btn.classList.remove("clicked");
  header_menu.classList.remove("opened");

  window.scrollTo({ top: 0 });
});

export default router;
