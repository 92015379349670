<template>
    <div class="footer_container">
        <footer>
            <ul>
                <li>비즈니스 연락처 : biz@humanvalue.co.kr</li>
                <li>Creator & Professor 지원 : creator@humanvalue.co.kr</li>
                <li>
                    <ul>
                        <li>전화 : 02-2062-5170</li>
                        <li>|</li>
                        <li>Humanvalue CO. LTD</li>
                        <li>|</li>
                        <li>서울시 영등포구 양평로 22길 21</li>
                    </ul>
                </li>
                <!-- <li>
                    <ul>
                        <li @click="onClickModal_terms" class="policy">
                            이용약관
                        </li>
                        <li>|</li>
                        <li @click="onClickModal_policy" class="policy">
                            개인정보처리방침
                        </li>
                    </ul>
                </li> -->
            </ul>
        </footer>
        <!-- <Terms_modal /> -->
        <!-- <Policy_modal /> -->
    </div>
</template>

<!-- <script>
import Terms_modal from "@/components/Terms_modal";
import Policy_modal from "@/components/Policy_modal";
export default {
    components: {
        Terms_modal,
        Policy_modal,
    },
    methods: {
        onClickModal_terms() {
            const modal_wrap = document.querySelector(".modal_wrap_terms");
            if (modal_wrap.classList.contains("closed")) {
                modal_wrap.classList.remove("closed");
                document.body.classList.add("scroll_fixed");
            }
        },
        onClickModal_policy() {
            const modal_wrap = document.querySelector(".modal_wrap_policy");
            if (modal_wrap.classList.contains("closed")) {
                modal_wrap.classList.remove("closed");
                document.body.classList.add("scroll_fixed");
            }
        },
    },
};
</script> -->

<style scoped src="@/assets/css/components/footer.css"></style>
