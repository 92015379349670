<template>
    <div class="header_container">
        <header>
            <div v-if="this.windowSize > 600" class="logo_wrap">
                <router-link to="/" class="logo_color">
                    <img src="@/assets/image/common/logo_color.svg" alt="" />
                </router-link>
                <router-link to="/" class="logo_white">
                    <img src="@/assets/image/common/logo_white.svg" alt="" />
                </router-link>
            </div>
            <div v-else class="logo_wrap symbol">
                <router-link to="/" class="logo_color">
                    <img
                        src="@/assets/image/common/logo_symbol_color.svg"
                        alt=""
                    />
                </router-link>
                <router-link to="/" class="logo_white">
                    <img
                        src="@/assets/image/common/logo_symbol_white.svg"
                        alt=""
                    />
                </router-link>
            </div>
            <div class="btn_wrap">
                <router-link to="/contact">
                    <button type="button" class="c_btn-white c_btn-small">
                        <p class="font_english">Contact</p>
                    </button>
                </router-link>
                <div class="btn_menu" @click="onClickMenu">
                    <div class="header_line"></div>
                    <div class="header_line"></div>
                    <div class="header_line"></div>
                </div>
            </div>
        </header>
    </div>
    <div class="menu_wrap">
        <div class="menu_inner">
            <div class="l_motion">
                <Vue3Lottie
                    ref="logo"
                    :animationData="logoJSON"
                    :loop="false"
                    :autoplay="false"
                />
            </div>
            <ul>
                <li class="font_english">
                    <router-link to="/program/recommend">Contents</router-link>
                </li>
                <li class="font_english">
                    <router-link to="/partner">Special Partner</router-link>
                </li>
                <li class="font_english">
                    <router-link to="/contact">Contact</router-link>
                </li>
                <li class="font_english">
                    <router-link to="/about">About</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { Vue3Lottie } from "vue3-lottie";
import "vue3-lottie/dist/style.css";
import logoJSON from "@/assets/image/movie/logo_motion.json";
export default {
    components: {
        Vue3Lottie,
    },
    data() {
        return {
            windowSize: window.innerWidth,
            logoJSON,
        };
    },
    mounted() {
        window.addEventListener("resize", this.getWindowSize);
        window.addEventListener("scroll", this.watchScroll);
    },
    unmounted() {
        window.removeEventListener("resize", this.getWindowSize);
        window.removeEventListener("scroll", this.watchScroll);
    },
    methods: {
        getWindowSize() {
            this.windowSize = window.innerWidth;
        },
        onClickMenu() {
            const header_btn = document.querySelector(
                ".header_container .btn_menu"
            );
            const logo_motion_move = document.querySelector(".l_motion .move");
            const header_menu = document.querySelector(".menu_wrap");
            let timeoutId = null;

            if (header_btn.classList.contains("clicked")) {
                header_btn.classList.remove("clicked");
                header_menu.classList.remove("opened");
                // document.body.classList.remove("scroll_fixed");
                console.log("잘가");
                clearTimeout(timeoutId);
            } else {
                header_btn.classList.add("clicked");
                header_menu.classList.add("opened");
                // document.body.classList.add("scroll_fixed");
                this.$refs["logo"].stop();
                timeoutId = setTimeout(() => {
                    this.$refs["logo"].play();
                }, 300);
            }
        },
        watchScroll() {
            if (window.scrollY < 20) {
                document
                    .querySelector(".header_container")
                    .classList.remove("header_scrdown");
            } else {
                document
                    .querySelector(".header_container")
                    .classList.add("header_scrdown");
            }
        },
    },
};
</script>

<style scoped src="@/assets/css/components/header.css"></style>
