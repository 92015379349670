<template>
    <div class="contact_banner">
        <router-link to="/contact">
            <button
                v-slide.vertical="{ delay: 150 }"
                type="button"
                class="c_btn-white c_btn-large"
            >
                <p class="font_english">Online Contact Us</p>
            </button>
        </router-link>
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/components/contact_banner.css"></style>
